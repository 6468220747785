@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */

body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Source Sans 3', sans-serif;
  }
a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
  }
.container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(8,0,58,0.7), rgba(8,0,58,0.7)), url('./assets/school\ building.jpg');
    background-size: cover;
    background-position: center;
    justify-content: center;
}
/* .top-container{
      padding-left: -10%;
      padding-right: 10%;
} */
nav{
    width: 90%;
    color: #fff;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}
.logo{
    width: 80px;
}
nav ul li, .dropdown{
    display: inline-block;
    list-style: none;
    margin: 5px 15px;
    font-size: 16px;
}

/* School drop down button and list */
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 17px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: steelblue;
  }
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }


.btn{
    background: #fff;
    color: #212121;
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
}
.tbtn{
    background: #192faa;
    color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
      display: inline-flex;
  align-items: center;
  justify-content: center;
  
}
.background{
    /* width: 100%; */
    /* padding-top: 60px; */
    /* min-height: 100vh;
    background: linear-gradient(rgba(8,0,58,0.7), rgba(8,0,58,0.7)), url('./assets/school\ building.jpg');
    background-size: cover; */
    /* padding: 10px; */
    /* padding-top: 60px; */
    background-position: center;
    
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-text{
    text-align: center;
    max-width: 800px;
}
.main-text h1{
    font-size: 60px;
    font-weight: 600;
}
.main-text p{
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

 .btn-container {
    margin-top: 20px;
}
.icon{
  position: 0;
  top: 0;
  right: 0;
  width: 15px;
}
.btn-icon{
  display: inline-block;
  margin: 5px;
  margin-top: 25px;
}

.sbtn {
    padding: 13px 25px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    color: #212121;
    background: #fff;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;

}

.sbtn:hover {
    background-color: #0056b3;
} 

@media (max-width: 768px) {
    .background {
      height: 100vh;
      padding: 10px;
      padding-top: 30px; /* adjust padding-top for small devices */
      background-position: center;
    }
    .main-text {
        max-width: 100%;
    }
    .main-text h1 {
        font-size: 40px;
    }
    .main-text p {
        max-width: 100%;
        margin: 10px 0;
    }
  }
@media (max-width: 568px) {
    .background {
      height: 100vh;
      padding: 10px;
      padding-top: 50px; /* adjust padding-top for small devices */
      background-position: center;
    }
    .main-text {
        max-width: 100%;
    }
    .main-text h1 {
        font-size: 30px;
    }
    .main-text p {
        max-width: 100%;
        font-size: 15px;
    }

    @media (max-width: 768px) and  (orientation: landscape) {
        .background {
          height: 100vh;
          padding: 10px;
          padding-top: 40px; /* adjust padding-top for small devices */
          background-position: center;
        }
        .main-text {
            max-width: 100%;
        }
        .main-text h1 {
            font-size: 20px;
        }
        .main-text p {
            max-width: 100%;
            font-size: 14px;
        }
        .btn-icon{
            margin: 5px;
            margin-top: 5px;
          }
          .sbtn {
              font-size: 10;  
          }
    }
  }